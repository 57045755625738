import { useState, useRef, useEffect, useContext } from "react";

import { useParams, useNavigate } from "react-router-dom";
import {
	InputLabel,
	Modal,
	TextField,
	Select,
	MenuItem,
	FormControl,
	Button,
	Alert,
	AlertTitle,
} from "@mui/material";
import { useToasts } from "react-toast-notifications";

import ImageSelectorPlaceholder from "../../Assets/IMG/ImageSelectorPlaceholder.svg";
import { DatePicker } from "@mui/x-date-pickers";
import {
	CountriesList,
	CovidVaccinationDosage,
	Diseases,
	initialSurrogate,
} from "../../Assets/Data";
import dayjs from "dayjs";

import Confirmation from "../Confirmation";
import SurrogateReportCreate from "../SurrogateReportCreate";
import { UploadFile } from "../../API/FetchData";
import { validatePhone } from "../../Lib/Validate";
import { validateBVN, validateEmail, validateNIN } from "../../App";
import { PerformRequest } from "../../API/PerformRequests";
import { DefaultContext } from "../Dashboard";

const experiences = [
	{
		name: "None",
		value: "0"
	},
	{
		name: "Once",
		value: "1"
	},
	{
		name: "Twice",
		value: "2"
	},
	{
		name: "Three Times",
		value: "3"
	}
]

const initialSurrogateForm = {
	firstName: "",
	lastName: "",
	dateOfBirth: "",
	placeOfBirth: "",
	address: "",
	primaryPhone: "",
	primaryEmailAddress: "",
	bankVerificationNumber: "",
	nationalIdentificationNumber: "",
	primaryImage: undefined,
	secondaryImage: undefined,

	tribe: "",
	religion: "",
	experience: "",
	spouseFirstName: "",
	spouseLastName: "",
	secondaryEmailAddress: "",
	secondaryPhone: "",
	sibling: {
		fullname: "",
		relationship: "",
		phone: "",
	},
	// Form Section B
	knownDisease: "",
	covidVaccination: "0",
	firstTimeParent: "No",
	lastChildBirth: "",
	hivStatus: "",



	govtIdentificationFile: undefined,
	covidVaccinationFile: undefined,

	governmentID: "",
	covidVaccinationCertificate: "",


	nextOfKin: {
		name: "",
		address: "",
		phone: "",
		relationship: "",
		nationalIdentificationNumber: "",

		area: "",
		state: "",
		city: "",
		country: "",
	},

	hepatitisStatus: "",
	weight: "",
	height: "",
	bmi: "",
	bloodGroup: "",
	genoType: "",

	area: "",
	state: "",
	city: "",
	country: "",

	status: ""
};

export default function SurrogateUpdate({
	showUpdateSurrogateModal,
	// surrogate,
}) {
	const consumeContext = useContext(DefaultContext);

	const { Tribes, Religions, BloodGroups, GeneTypes } = consumeContext;

	const navigate = useNavigate();
	const params = useParams();
	const { addToast, removeAllToasts } = useToasts();

	const instructionRef = useRef(null)

	const [errors, setErrors] = useState([])

	const [countriesList, setCountriesList] = useState(CountriesList);
	const [surrogateForm, setSurrogateForm] = useState(initialSurrogateForm);
	const [surrogateFormExtendedInfo, setSurrogateFormExtendedInfo] = useState(
		// initialSurrogate.extendedInfo
		[]
	);
	const primaryImageUploadRef = useRef();
	const secondaryImageUploadRef = useRef();

	const govtIdentificationUploadRef = useRef();
	const covidVaccinationUploadRef = useRef();
	const defaultFullInputProps = {
		variant: "standard",
		spellCheck: false,
		className: "modal-input-full px-14",
	};
	const defaultHalfInputProps = {
		variant: "standard",
		className: "modal-input-half px-14",
		spellCheck: false,
	};

	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	const getConfirmationModalStatus = (value) => {
		setShowConfirmationModal(value);
		if (!value) {
			showUpdateSurrogateModal(false);
		}
	};

	const fileIsLarge = () => {
		addToast("Max File Size: 1.5MB", { appearance: "error" });
	};
	const [imageUploading, setImageUploading] = useState(false);

	const getFileName = (fileString) => {
		if (!fileString || fileString.length === 0) {
			return "No File Found";
		} else {
			let url = new URL(fileString);
			const lastIndex = url.pathname.lastIndexOf("/");
			const name = url.pathname.substring(lastIndex).replaceAll("/", "");
			if (name.length > 20) {
				return name.substring(name.length, name.length - 20);
			} else {
				return name;
			}
		}
	};
	const checkOrUploadFile = async (isFile, prevFile) => {
		if (!isFile) {
			return prevFile;
		} else {
			let fileFormData = new FormData();
			fileFormData.append(
				"file",
				isFile,
				isFile.name.toLowerCase().split(" ").join().replaceAll(",", "")
			);
			const uploadFile = await UploadFile({
				formData: fileFormData,
			});
			return uploadFile.data.fileUrl;
		}
	};
	// const UploadSurrogateFile = async (label, file) => {
	// 	let fileFormData = new FormData();

	// 	fileFormData.append(
	// 		"file",
	// 		file,
	// 		file.name.toLowerCase().split(" ").join().replaceAll(",", "")
	// 	);
	// 	setImageUploading(true);
	// 	const uploadFile = await UploadFile({
	// 		formData: fileFormData,
	// 	});
	// 	console.log(uploadFile);
	// 	setImageUploading(false);
	// 	if (label === "Primary") {
	// 		setSurrogateForm({
	// 			...surrogateForm,
	// 			primary: {
	// 				...surrogateForm.primary,
	// 				mainImage: uploadFile.data.fileUrl,
	// 			},
	// 		});
	// 	}
	// 	if (label === "Secondary") {
	// 		setSurrogateForm({
	// 			...surrogateForm,
	// 			primary: {
	// 				...surrogateForm.primary,
	// 				secondImage: uploadFile.data.fileUrl,
	// 			},
	// 		});
	// 	}
	// 	if (label === "Government") {
	// 		setSurrogateForm({
	// 			...surrogateForm,
	// 			primary: {
	// 				...surrogateForm.primary,
	// 				governmentID: uploadFile.data.fileUrl,
	// 			},
	// 		});
	// 	}
	// 	if (label === "Covid") {
	// 		setExtendedInfoValue(
	// 			"covidVaccinationCertificate",
	// 			uploadFile.data.fileUrl
	// 		);
	// 	}
	// };

	const [formSubmitting, setFormSubmitting] = useState(false);
	const [formErrors, setFormErrors] = useState({
		firstName: false,
		lastName: false,
		dateOfBirth: false,
		placeOfBirth: false,
		address: false,
		tribe: false,
		religion: false,
		experience: false,
		primaryPhone: false,
		primaryEmailAddress: false,
		bankVerificationNumber: false,
		nationalIdentificationNumber: false,
		primaryImage: false,
		secondaryImage: false,

		spouseFirstName: false,
		spouseLastName: false,
		secondaryEmailAddress: false,
		secondaryPhone: false,

		// Form Section B
		knownDisease: false,
		covidVaccination: false,
		firstTimeParent: false,
		lastChildBirth: false,
		hivStatus: false,
		govtIdentificationFile: false,
		covidVaccinationFile: false,
		nextOfKin_name: false,
		nextOfKin_phone: false,
		nextOfKin_address: false,
		nextOfKin_nationalIdentificationNumber: false,
		nextOfKin_area: false,
		nextOfKin_state: false,
		nextOfKin_city: false,
		nextOfKin_country: false,

		// sibling_name: false,
		// sibling_phone: false,

		hepatitisStatus: false,
		weight: false,
		height: false,
		bmi: false,
		bloodGroup: false,
		genoType: false,

		area: false,
		state: false,
		city: false,
		country: false,
	});
	const UpdateFormErrors = () => {
		const newErrors = []

		const isPrimaryPhoneValid = validatePhone(surrogateForm.primaryPhone);
		if (!isPrimaryPhoneValid) newErrors.push("Primary phone is not valid")

		const isEmailValid = validateEmail(surrogateForm.primaryEmailAddress);
		if (!isEmailValid) newErrors.push("Email is not valid")

		const is_BVN_Valid = validateBVN(surrogateForm.bankVerificationNumber);
		if (!is_BVN_Valid) newErrors.push("BVN is not valid")

		const is_NIN_Valid = validateNIN(
			surrogateForm.nationalIdentificationNumber
		);
		if (!is_NIN_Valid) newErrors.push("NIN is not valid")

		const isNexfOfKinPrimaryPhoneValid =
			validatePhone(surrogateForm.nextOfKin.phone) &&
			surrogateForm.primaryPhone !== surrogateForm.nextOfKin.phone
		if (!isNexfOfKinPrimaryPhoneValid) newErrors.push("Next of Kin Phone is not valid")

		const isSiblingPhoneValid =
			validatePhone(surrogateForm.sibling.phone) &&
			surrogateForm.primaryPhone !== surrogateForm.sibling.phone &&
			surrogateForm.sibling.phone !== surrogateForm.nextOfKin.phone;

		const isNexfOfKinNINValid = validateNIN(
			surrogateForm.nextOfKin.nationalIdentificationNumber
		);
		if (!isNexfOfKinNINValid) newErrors.push("Next of Kin NIN is not valid")


		if (surrogateForm.firstName.length === 0) newErrors.push("First Name is required")

		if (surrogateForm.lastName.length === 0) newErrors.push("Last Name is required")

		if (surrogateForm.placeOfBirth.length === 0) newErrors.push("Place of Birth is required")

		if (surrogateForm.address.length === 0) newErrors.push("Address is required")

		if (surrogateForm.area.length === 0) newErrors.push("Area is required")
		if (surrogateForm.state.length === 0) newErrors.push("State is required")
		if (surrogateForm.city.length === 0) newErrors.push("City is required")
		if (surrogateForm.country.length === 0) newErrors.push("Country is required")


		if (surrogateForm.tribe.length === 0) newErrors.push("Tribe is required")

		if (surrogateForm.religion.length === 0) newErrors.push("Religion is required")

		if (surrogateForm.experience.length === 0) newErrors.push("Experience is required")



		if (surrogateForm.nextOfKin.name.length === 0) newErrors.push("Next of Kin Name is required")

		if (surrogateForm.nextOfKin.address.length === 0) newErrors.push("Next of Kin Address is required")


		if (surrogateForm.nextOfKin.area.length === 0) newErrors.push("Next of Kin Area is required")
		if (surrogateForm.nextOfKin.state.length === 0) newErrors.push("Next of Kin State is required")
		if (surrogateForm.nextOfKin.city.length === 0) newErrors.push("Next of Kin City is required")
		if (surrogateForm.nextOfKin.country.length === 0) newErrors.push("Next of Kin Country is required")

		if (surrogateForm.nextOfKin.relationship.length === 0) newErrors.push("Next of Kin Relationship is required")


		if (!surrogateForm.primaryImage && surrogateForm.mainImage.length === 0) newErrors.push("Primary Image is required")

		if (!surrogateForm.secondaryImage && surrogateForm.secondImage.length === 0) newErrors.push("Secondary Image  is required")

		if (!surrogateForm.govtIdentificationFile && surrogateForm?.governmentID === 0) newErrors.push("Government Identification is required")

		if (!surrogateForm.covidVaccinationFile && surrogateForm?.covidVaccinationCertificate === 0) newErrors.push("Covid Vaccination File is required")

		if (surrogateForm.hepatitisStatus.length === 0) newErrors.push("Hepatitis Status is required")

		if (surrogateForm.weight.length === 0) newErrors.push("Weight is required")

		if (surrogateForm.height.length === 0) newErrors.push("Height is required")


		if (surrogateForm.bmi.length === 0) newErrors.push("BMI is required")

		if (surrogateForm.bloodGroup.length === 0) newErrors.push("Blood Group is required")

		if (surrogateForm.genoType.length === 0) newErrors.push("Genotype is required")

		if (surrogateForm.status.length === 0) newErrors.push("Status is required")

		setErrors(newErrors)


		const concludedErrors = {
			...formErrors,
			firstName: surrogateForm.firstName.length === 0,
			lastName: surrogateForm.lastName.length === 0,
			address: surrogateForm.address.length === 0,

			placeOfBirth: surrogateForm.placeOfBirth.length === 0,

			area: surrogateForm.area.length === 0,
			state: surrogateForm.state.length === 0,
			city: surrogateForm.city.length === 0,
			country: surrogateForm.country.length === 0,


			primaryPhone: !isPrimaryPhoneValid,
			primaryEmailAddress: !isEmailValid,
			bankVerificationNumber: !is_BVN_Valid,
			nationalIdentificationNumber: !is_NIN_Valid,

			tribe: surrogateForm.tribe.length === 0,
			religion: surrogateForm.religion.length === 0,
			experience: surrogateForm.experience.length === 0,

			// sibling_phone: !isSiblingPhoneValid,
			// sibling_name: surrogateForm.sibling.fullname.length === 0,

			nextOfKin_name: surrogateForm.nextOfKin.name.length === 0,
			nextOfKin_address: surrogateForm.nextOfKin.address.length === 0,
			nextOfKin_phone: !isNexfOfKinPrimaryPhoneValid,
			nextOfKin_nationalIdentificationNumber: !isNexfOfKinNINValid,

			nextOfKin_area: surrogateForm.nextOfKin.area.length === 0,
			nextOfKin_state: surrogateForm.nextOfKin.state.length === 0,
			nextOfKin_city: surrogateForm.nextOfKin.city.length === 0,
			nextOfKin_country: surrogateForm.nextOfKin.country.length === 0,

			nextOfKin_relationship: surrogateForm.nextOfKin.relationship.length === 0,

			primaryImage: !surrogateForm?.primaryImage && surrogateForm.mainImage.length === 0,
			secondaryImage: !surrogateForm?.secondaryImage && surrogateForm.secondImage.length === 0,

			govtIdentificationFile: !surrogateForm.govtIdentificationFile && surrogateForm?.governmentID === 0,
			covidVaccinationFile: !surrogateForm.covidVaccinationFile && surrogateForm?.covidVaccinationCertificate === 0,


			hepatitisStatus: surrogateForm.hepatitisStatus.length === 0,
			weight: surrogateForm.weight.length === 0,
			height: surrogateForm.height.length === 0,
			bmi: surrogateForm.bmi.length === 0,
			bloodGroup: surrogateForm.bloodGroup.length === 0,
			genoType: surrogateForm.genoType.length === 0,

			status: surrogateForm.status.length === 0
		}

		setFormErrors(concludedErrors);
		return concludedErrors
		// console.log(formErrors);
	};

	// useEffect(() => {
	// 	UpdateSurrogate();
	// }, [formErrors]);

	const [surrogateLoading, setSurrogateLoading] = useState(false);

	const fetchSurrogate = async () => {
		setSurrogateLoading(true);
		const { surrogateID } = params;
		const r = await PerformRequest.GetAllSurrogates({ surrogateID }).catch(
			() => {
				setSurrogateLoading(false);
			}
		);
		console.log(r);
		if (r.data.status === "success" && r.data.data) {
			console.log("Surrogate Found");
			const { primary, extendedInfo, ...rest } = r.data.data[0]
			const { nok, firstname: firstName, lastname: lastName, phone: primaryPhone, email: primaryEmailAddress, bvn: bankVerificationNumber, nin: nationalIdentificationNumber, ...primaryRest } = primary
			const { fullname: name, nin: nationalIdentificationNumberNOK, ...restNok } = nok

			const updatedExtendedInfo = extendedInfo.reduce((acc, item) => {
				const key = item.field === "disease" ? "knownDisease" : item.field;
				acc[key] = item.value;
				return acc;
			}, {});

			setSurrogateForm({
				...surrogateForm,
				...rest,
				...primaryRest,
				nextOfKin: {
					...surrogateForm.nextOfKin,
					...restNok,
					name,
					nationalIdentificationNumber: nationalIdentificationNumberNOK
				},
				...updatedExtendedInfo,
				firstName,
				lastName,
				primaryPhone: primaryPhone,
				primaryEmailAddress,
				bankVerificationNumber,
				nationalIdentificationNumber,
			});
			setSurrogateFormExtendedInfo(r.data.data[0].extendedInfo);
		}
		setSurrogateLoading(false);
	};


	useEffect(() => {
		fetchSurrogate();
	}, []);

	const getExtendedInfoValue = (field) => {
		const filter = surrogateFormExtendedInfo.filter(
			(info) => info.field === field
		);
		if (filter.length === 0) {
			return "Not Found";
		} else {
			const info = filter[0];
			return info.value;
		}
	};

	const setExtendedInfoValue = (field, value) => {
		const filter = surrogateFormExtendedInfo.filter(
			(info) => info.field === field
		);
		if (filter.length === 0) {
			console.log("Not found");
			return "Not Found";
		} else {
			let info = filter[0];
			info = { ...info, value: value };
			setSurrogateFormExtendedInfo([
				...surrogateFormExtendedInfo.filter((i) => i.field !== field),
				info,
			]);
		}
	};

	useEffect(() => {
		console.log("New extended information", surrogateFormExtendedInfo);
	}, [surrogateFormExtendedInfo]);

	const resetFormErrors = () => {
		setFormErrors({
			firstName: false,
			lastName: false,
			dateOfBirth: false,
			placeOfBirth: false,
			address: false,
			tribe: false,
			religion: false,
			experience: false,
			primaryPhone: false,
			primaryEmailAddress: false,
			bankVerificationNumber: false,
			nationalIdentificationNumber: false,
			primaryImage: false,
			secondaryImage: false,

			spouseFirstName: false,
			spouseLastName: false,
			secondaryEmailAddress: false,
			secondaryPhone: false,

			// Form Section B
			knownDisease: false,
			covidVaccination: false,
			firstTimeParent: false,
			lastChildBirth: false,
			hivStatus: false,
			govtIdentificationFile: false,
			covidVaccinationFile: false,
			nextOfKin_name: false,
			nextOfKin_phone: false,
			nextOfKin_address: false,
			nextOfKin_nationalIdentificationNumber: false,
			nextOfKin_area: false,
			nextOfKin_state: false,
			nextOfKin_city: false,
			nextOfKin_country: false,
			nextOfKin_relationship: false,

			// sibling_name: false,
			// sibling_phone: false,

			hepatitisStatus: false,
			weight: false,
			height: false,
			bmi: false,
			bloodGroup: false,
			genoType: false,

			area: false,
			state: false,
			city: false,
			country: false,
		})
	}



	const scrollToInstruction = () => {
		if (instructionRef?.current) {
			window.scroll({
				top: instructionRef.current.offsetTop,
				left: 0,
				behavior: 'smooth'
			});
		}
	}

	const UpdateSurrogate = async () => {
		console.log(formErrors);

		console.log(surrogateForm)
		setErrors([])
		resetFormErrors()

		let concludedErrors = UpdateFormErrors()

		const newErrors = Object.values(concludedErrors).filter((e) => e === true);

		if (newErrors.length > 0) {
			setFormSubmitting(false);
			scrollToInstruction()
			addToast("Please fill the form correctly", { appearance: "error" });
		} else {
			setFormSubmitting(true);


			let primaryImageFormData = new FormData();
			if (surrogateForm.primaryImage) {
				primaryImageFormData.append(
					"file",
					surrogateForm.primaryImage,
					surrogateForm.primaryImage.name
						.toLowerCase()
						.split(" ")
						.join()
						.replaceAll(",", "")
				);
			}


			let secondaryImageFormData = new FormData();
			if (surrogateForm.secondaryImage) {
				secondaryImageFormData.append(
					"file",
					surrogateForm.secondaryImage,
					surrogateForm.secondaryImage.name
						.toLowerCase()
						.split(" ")
						.join()
						.replaceAll(",", "")
				);
			}


			let govtIDFormData = new FormData();
			if (surrogateForm.govtIdentificationFile) {
				govtIDFormData.append(
					"file",
					surrogateForm.govtIdentificationFile,
					surrogateForm.govtIdentificationFile.name
						.toLowerCase()
						.split(" ")
						.join()
						.replaceAll(",", "")
				);
			}


			let covidVaccinationFormData = new FormData();
			if (surrogateForm.covidVaccinationFile) {
				covidVaccinationFormData.append(
					"file",
					surrogateForm.covidVaccinationFile,
					surrogateForm.covidVaccinationFile.name
						.toLowerCase()
						.split(" ")
						.join()
						.replaceAll(",", "")
				);
			}

			let uploadPrimaryImage, uploadSecondaryImage, uploadGovtId, uploadCovidVaccination

			if (surrogateForm.primaryImage) {
				uploadPrimaryImage = await UploadFile({
					formData: primaryImageFormData,
				});
			}


			if (surrogateForm.secondaryImage) {
				uploadSecondaryImage = await UploadFile({
					formData: secondaryImageFormData,
				});
			}

			if (surrogateForm.govtIdentificationFile) {
				uploadGovtId = await UploadFile({
					formData: govtIDFormData,
				});
			}

			if (surrogateForm.covidVaccinationFile) {
				uploadCovidVaccination = await UploadFile({
					formData: covidVaccinationFormData,
				});
			}


			const {
				name: nextOfKin_name,
				address: nextOfKin_address,
				phone: nextOfKin_phone,
				nationalIdentificationNumber: nextOfKin_nationalIdentificationNumber,
				relationship: nextOfKin_relationship,

				area: nextOfKin_area,
				state: nextOfKin_state,
				city: nextOfKin_city,
				country: nextOfKin_country,
			} = surrogateForm.nextOfKin;

			const data = {
				surrogateID: surrogateForm.id,
				firstname: surrogateForm.firstName,
				lastname: surrogateForm.lastName,
				email: surrogateForm.primaryEmailAddress,
				phone: surrogateForm.primaryPhone,
				address: surrogateForm.address,

				area: surrogateForm?.area,
				state: surrogateForm?.state,
				city: surrogateForm?.city,
				country: surrogateForm?.country,

				dateOfBirth: dayjs(surrogateForm.dateOfBirth).format("YYYY-MM-DD"),
				tribe: surrogateForm.tribe,
				religion: surrogateForm.religion,
				experience: surrogateForm.experience,
				placeOfBirth: surrogateForm.placeOfBirth,
				mainImage: uploadPrimaryImage?.data?.fileUrl ?? surrogateForm?.mainImage,
				secondImage: uploadSecondaryImage?.data?.fileUrl ?? surrogateForm?.secondImage,
				nin: surrogateForm.nationalIdentificationNumber,
				bvn: surrogateForm.bankVerificationNumber,
				governmentID: uploadGovtId?.data?.fileUrl ?? surrogateForm?.governmentID,

				nok: {
					fullname: nextOfKin_name,
					relationship: nextOfKin_relationship,
					address: nextOfKin_address,
					phone: nextOfKin_phone,
					nin: nextOfKin_nationalIdentificationNumber,

					area: nextOfKin_area,
					state: nextOfKin_state,
					city: nextOfKin_city,
					country: nextOfKin_country,
				},
				sibling: {
					fullname: surrogateForm.sibling.fullname,
					relationship: surrogateForm.sibling.relationship,
					phone: surrogateForm.sibling.phone,
				},

				extendedInfo: {
					disease: surrogateForm.knownDisease,
					covidVaccination: surrogateForm.covidVaccination,
					covidVaccinationCertificate: uploadCovidVaccination?.data?.fileUrl ?? surrogateForm?.covidVaccinationCertificate,
					firstTimeParent: surrogateForm.firstTimeParent,
					hivStatus: surrogateForm.hivStatus,
					lastChildBirthTime: dayjs(surrogateForm.lastChildBirth).format(
						"YYYY-MM-DD"
					),
					hepatitisStatus: surrogateForm.hepatitisStatus,
					weight: surrogateForm.weight,
					height: surrogateForm.height,
					bmi: surrogateForm.bmi,
					bloodGroup: surrogateForm.bloodGroup,
					genoType: surrogateForm.genoType,
				},
			};
			const updateRequest = await PerformRequest.UpdateSurrogate(data).catch(
				() => {
					setFormSubmitting(false);
				}
			);
			setFormSubmitting(false);
			console.log(updateRequest);
			const { message: responseMessage } = updateRequest.data;
			if (updateRequest.data.status === "failed") {
				addToast(responseMessage, { appearance: "error" });
			} else {
				addToast(responseMessage, { appearance: "success" });
				// setShowConfirmationModal(true);
				// showUpdateSurrogateModal(false);
				consumeContext.refetchData();
				fetchSurrogate();
			}
		}
	};
	return (
		<div>
			{showConfirmationModal && (
				<Confirmation
					modalHeaderText=""
					modalBodyText="Profile Updated Successfully"
					modalAction={{
						method: () => {
							setShowConfirmationModal(false);
						},
						text: "Close",
					}}
					modalLink={{
						text: "Back to Dashboard",
						route: "/dashboard",
					}}
					getConfirmationModalStatus={getConfirmationModalStatus}
				/>
			)}

			<div>
				<input
					type="file"
					accept=".jpg,  .png"
					ref={primaryImageUploadRef}
					className="modal-image-hide"
					onChange={(e) => {
						const image = e.target.files[0];
						console.log(image);
						if (image.size > 1547220) {
							fileIsLarge();
						} else {
							setSurrogateForm({
								...surrogateForm,
								primaryImage: image,
							});
							setFormErrors({
								...formErrors,
								primaryImage: false
							});
						}
					}}
				/>
				<input
					type="file"
					accept=".jpg,  .png"
					ref={secondaryImageUploadRef}
					className="modal-image-hide"
					onChange={(e) => {
						console.log(e.target.files);
						const image = e.target.files[0];
						if (image.size > 1547220) {
							fileIsLarge();
						} else {
							setSurrogateForm({
								...surrogateForm,
								secondaryImage: image,
							});
							setFormErrors({
								...formErrors,
								secondaryImage: false
							});
						}
					}}
				/>
				<input
					type="file"
					accept=".pdf, .jpg,  .png"
					ref={govtIdentificationUploadRef}
					className="modal-image-hide"
					onChange={(e) => {
						const file = e.target.files[0];
						console.log(file);
						if (file.size > 1547220) {
							fileIsLarge();
						} else {
							setSurrogateForm({
								...surrogateForm,
								govtIdentificationFile: file,
							});
							setFormErrors({
								...formErrors,
								govtIdentificationFile: false
							});
						}
					}}
				/>
			</div>

			<input
				type="file"
				accept=".pdf, .jpg,  .png"
				ref={covidVaccinationUploadRef}
				className="modal-image-hide"
				onChange={(e) => {
					console.log(e.target.files);
					const file = e.target.files[0];
					if (file.size > 1547220) {
						fileIsLarge();
					} else {
						setSurrogateForm({
							...surrogateForm,
							covidVaccinationFile: file,
						});
						setFormErrors({
							...formErrors,
							covidVaccinationFile: false
						});
					}
				}}
			/>
			{/* <Modal
        open={isModalOpen}
        onClose={(e, reason) => {
          if (reason === "backdropClick") {
            setModalOpen(false);
            showUpdateSurrogateModal(false);
          }
        }}
        className="default-modal-container flex-row"
      > */}
			{/* <div className="default-modal-content modal-scrollbar surrogate-report-modal flex-column"> */}
			<div className="surrogate-content flex-column" ref={instructionRef}>
				<span className="cinzel px-30 uppercase">update surrogate profile</span>
				<br />
				<span className="modal-about poppins px-15">
					Fill in the data for surrogate profile correctly. It will take a
					couple of minutes
				</span>


				{
					errors.length > 0 ?
						<div style={{
							marginTop: "1rem",
							marginBottom: "1rem",
						}}>
							<Alert severity="warning">
								<AlertTitle>Validation Error</AlertTitle>
								{
									errors.map((item, index) => (
										<p key={`error-${index}`}>{item}</p>
									))
								}
							</Alert>
						</div>
						: null
				}

				<div className="modal-form-container flex-row">
					<div className="modal-form flex-column">
						<br />
						<span className="fw-600 poppins px-24">Surrogate Bio-Data</span>
						<br />
						<div className="flex-row space-between modal-input-row">
							<TextField
								label="First Name"
								value={surrogateForm.firstName}
								error={formErrors.firstName}
								{...defaultHalfInputProps}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										firstName: e.target.value,
									})
									setFormErrors({
										...formErrors,
										firstName: false
									});
								}}
								placeholder="First Name"
							/>
							<TextField
								label="Last Name"
								value={surrogateForm.lastName}
								error={formErrors.lastName}
								{...defaultHalfInputProps}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										lastName: e.target.value,
									})
									setFormErrors({
										...formErrors,
										lastName: false
									});
								}}
								placeholder="Last Name"
							/>
						</div>
						<div className="flex-row space-between modal-input-row">
							<DatePicker
								defaultValue={dayjs("2023-01-01")}
								{...defaultHalfInputProps}
								slotProps={{
									textField: { variant: "standard" },
								}}
								value={dayjs(surrogateForm.dateOfBirth)}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										dateOfBirth: dayjs(e).format("YYYY-MM-DD"),
									});
									setFormErrors({
										...formErrors,
										dateOfBirth: false
									});
								}}
								label="Date of Birth"
								placeholder="Date of Birth"
							/>
							<FormControl variant="standard" {...defaultHalfInputProps}>
								<InputLabel id="demo-simple-select-standard-label">
									Place of Birth
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={surrogateForm.placeOfBirth}
									error={formErrors.placeOfBirth}
									onChange={(e) => {
										setSurrogateForm({
											...surrogateForm,
											placeOfBirth: e.target.value,
										});
										setFormErrors({
											...formErrors,
											placeOfBirth: false
										});
									}}
									label="Place of Birth"
									placeholder="Place of Birth"
								>
									{countriesList.map((country, index) => {
										return (
											<MenuItem value={country.name} key={country.name}>
												{country.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>
						<div className="flex-row space-between modal-input-row">
							<TextField
								label="Area"
								value={surrogateForm.area}
								error={formErrors.area}
								{...defaultFullInputProps}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										area: e.target.value,
									})
									setFormErrors({
										...formErrors,
										area: false
									});
								}}
								placeholder="Area"
							/>
						</div>
						<div className="flex-row space-between modal-input-row">
							<TextField
								label="Address"
								value={surrogateForm.address}
								error={formErrors.address}
								{...defaultFullInputProps}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										address: e.target.value,
									})
									setFormErrors({
										...formErrors,
										address: false
									});
								}}
								placeholder="Address"
							/>
						</div>
						<div className="flex-row space-between modal-input-row">
							<TextField
								label="State"
								value={surrogateForm.state}
								error={formErrors.state}
								{...defaultHalfInputProps}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										state: e.target.value,
									})
									setFormErrors({
										...formErrors,
										state: false
									});
								}}
								placeholder="State"
							/>
							<TextField
								label="City"
								value={surrogateForm.city}
								error={formErrors.city}
								{...defaultHalfInputProps}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										city: e.target.value,
									})
									setFormErrors({
										...formErrors,
										city: false
									});
								}}
								placeholder="City"
							/>
						</div>
						<div className="flex-row space-between modal-input-row">
							<FormControl variant="standard" {...defaultFullInputProps}>
								<InputLabel id="demo-simple-select-standard-label">
									Country
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={surrogateForm.country}
									onChange={(e) => {
										setSurrogateForm({
											...surrogateForm,
											country: e.target.value,
										});
										setFormErrors({
											...formErrors,
											country: false
										});
									}}
									label="Country"
									placeholder="Country"
									error={formErrors.country}
								>
									{countriesList.map((country, index) => {
										return (
											<MenuItem value={country.name} key={`${country.name}-${index}-country`}>
												{country.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>
						<div className="flex-row space-between modal-input-row">
							<FormControl variant="standard" {...defaultFullInputProps}>
								<InputLabel id="demo-simple-select-standard-label">
									How many times have you been a surrogate?
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={surrogateForm.experience}
									onChange={(e) => {
										setSurrogateForm({
											...surrogateForm,
											experience: e.target.value,
										});
										setFormErrors({
											...formErrors,
											experience: false
										});
									}}
									label="How many times have you been a surrogate?"
									placeholder="How many times have you been a surrogate?"
									error={formErrors.experience}
								>
									{experiences.map((item, index) => {
										return (
											<MenuItem value={item.value} key={`${item.name}-${index}-country`}>
												{item.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>
						<div className="flex-row space-between modal-input-row">
							<TextField
								label="Primary Phone Number"
								placeholder="0802-345-6789"
								value={surrogateForm.primaryPhone}
								error={formErrors.primaryPhone}
								{...defaultFullInputProps}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										primaryPhone: e.target.value,
									})
									setFormErrors({
										...formErrors,
										primaryPhone: false
									});
								}}
							// onBlur={() => {
							//   setSurrogateForm({
							//     ...surrogateForm,
							//     primaryPhone: surrogateForm.primaryPhone.replace(
							//       "+",
							//       ""
							//     ),
							//   });
							// }}
							/>
						</div>
						<div className="flex-row space-between modal-input-row">
							<TextField
								label="Primary Email Address"
								value={surrogateForm.primaryEmailAddress}
								error={formErrors.primaryEmailAddress}
								{...defaultFullInputProps}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										primaryEmailAddress: e.target.value,
									})
									setFormErrors({
										...formErrors,
										primaryEmailAddress: false
									});
								}}
								placeholder="Primary Email Address"
							/>
						</div>
						<div className="flex-row space-between modal-input-row">
							<TextField
								inputProps={{
									maxLength: 11,
								}}
								label="Bank Verification Number"
								value={surrogateForm.bankVerificationNumber}
								error={formErrors.bankVerificationNumber}
								{...defaultFullInputProps}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										bankVerificationNumber: e.target.value,
									})
									setFormErrors({
										...formErrors,
										bankVerificationNumber: false
									});
								}}
								placeholder="Bank Verification Number"
							/>
						</div>
						<div className="flex-row space-between modal-input-row">
							<TextField
								label="National Identification Number"
								inputProps={{
									maxLength: 11,
								}}
								value={surrogateForm.nationalIdentificationNumber}
								error={formErrors.nationalIdentificationNumber}
								{...defaultFullInputProps}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										nationalIdentificationNumber: e.target.value,
									})
									setFormErrors({
										...formErrors,
										nationalIdentificationNumber: false
									});
								}}
								placeholder="National Identification Number"
							/>
						</div>
					</div>

					<div className="flex-column modal-form-right space-between">
						<span className="flex-column align-center width-100">
							<div className="modal-form-image-container modal-form-image-container-small flex-row">
								<img
									src={surrogateForm.primaryImage ? URL.createObjectURL(surrogateForm.primaryImage) : surrogateForm.mainImage}
									alt=""
									className="modal-form-image"
								/>
							</div>
							<br />
							<span
								className="purple-btn-default px-16 poppins pointer width-100 surrogate-form-btn"
								onClick={() => {
									primaryImageUploadRef.current.click();
								}}
							>
								Upload Main Image
							</span>
							<br />
							<div className="modal-form-image-container modal-form-image-container-small flex-row">
								<img
									src={surrogateForm.secondaryImage ? URL.createObjectURL(surrogateForm.secondaryImage) : surrogateForm.secondImage}
									alt=""
									className="modal-form-image"
								/>
							</div>
							<br />
							<span
								className="purple-btn-default px-16 poppins pointer width-100 surrogate-form-btn"
								onClick={() => {
									secondaryImageUploadRef.current.click();
								}}
							>
								Upload Second Image
							</span>
						</span>
						<br />
					</div>
				</div>

				<div className="modal-form-container flex-row">
					<div className="modal-form flex-column">
						<br />
						<span className="fw-600 poppins px-24">
							Surrogate Health Information
						</span>
						<br />
						<div className="flex-row space-between modal-input-row">
							<FormControl variant="standard" {...defaultHalfInputProps}>
								<InputLabel id="demo-simple-select-standard-label">
									Any Known Disease/Ailment
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={getExtendedInfoValue("disease")}
									error={formErrors.disease}
									onChange={(e) => {
										setExtendedInfoValue("disease", e.target.value);
									}}
									label="Any Known Disease/Ailment"
								>
									{Diseases.map((disease, index) => {
										return (
											<MenuItem value={disease.value} key={disease.value}>
												{disease.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							<FormControl variant="standard" {...defaultHalfInputProps}>
								<InputLabel id="demo-simple-select-standard-label">
									Taken COVID Vaccination
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									error={formErrors.covidVaccination}
									value={getExtendedInfoValue("covidVaccination")}
									onChange={(e) => {
										setExtendedInfoValue("covidVaccination", e.target.value);
									}}
									label="Taken Covid Vaccination"
								>
									{CovidVaccinationDosage.map((vaccination, index) => {
										return (
											<MenuItem
												value={vaccination.value}
												key={vaccination.value}
											>
												{vaccination.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>
						<div className="flex-row space-between modal-input-row">
							<FormControl variant="standard" {...defaultHalfInputProps}>
								<InputLabel id="demo-simple-select-standard-label">
									First Time Parent?
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									error={formErrors.firstTimeParent}
									value={surrogateForm.firstTimeParent}
									onChange={(e) => {
										setSurrogateForm({
											...surrogateForm,
											firstTimeParent: e.target.value,
										});
										setFormErrors({
											...formErrors,
											firstTimeParent: false
										});
									}}
									label="First Time Parent?"
								>
									<MenuItem value={"No"} key="first-time-parent-false">
										No
									</MenuItem>
									<MenuItem value={"Yes"} key="first-time-parent-true">
										Yes
									</MenuItem>
								</Select>
							</FormControl>
							<DatePicker
								defaultValue={dayjs("2023-01-01")}
								{...defaultHalfInputProps}
								error={formErrors.lastChildBirthTime}
								slotProps={{
									textField: { variant: "standard" },
								}}
								value={dayjs(getExtendedInfoValue("lastChildBirthTime"))}
								onChange={(e) => {
									setExtendedInfoValue(
										"lastChildBirthTime",
										dayjs(e).format("YYYY-MM-DD")
									);
								}}
								label="Date of Last Child Birth"
							/>
						</div>
						<div className="flex-row space-between modal-input-row">
							<FormControl variant="standard" {...defaultFullInputProps}>
								<InputLabel id="demo-simple-select-standard-label">
									HIV/AIDS STATUS
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									error={formErrors.hivStatus}
									value={getExtendedInfoValue("hivStatus")}
									onChange={(e) => {
										setExtendedInfoValue("hivStatus", e.target.value);
									}}
									label="HIV/AIDS STATUS"
								>
									<MenuItem value={"Negative"} key="hiv-status-false">
										Negative
									</MenuItem>
									<MenuItem value={"Positive"} key="hiv-status-true">
										Positive
									</MenuItem>
								</Select>
							</FormControl>
						</div>

						<div className="flex-row space-between modal-input-row">
							<FormControl variant="standard" {...defaultHalfInputProps}>
								<InputLabel id="demo-simple-select-standard-label">
									⁠⁠Hepatitis Status
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={getExtendedInfoValue("hepatitisStatus")}
									onChange={(e) => {
										setExtendedInfoValue("hepatitisStatus", e.target.value);
									}}
									label="Hepatitis Status"
									error={formErrors.hepatitisStatus}
								>
									<MenuItem value={"Negative"} key="hiv-status-false">
										Negative
									</MenuItem>
									<MenuItem value={"Positive"} key="hiv-status-true">
										Positive
									</MenuItem>
								</Select>
							</FormControl>

							<TextField
								label="Weight"
								{...defaultHalfInputProps}
								value={getExtendedInfoValue("weight")}
								error={formErrors.weight}
								onChange={(e) => {
									setExtendedInfoValue("weight", e.target.value)
								}}
							/>
						</div>

						<div className="flex-row space-between modal-input-row">
							<TextField
								label="Height"
								{...defaultHalfInputProps}
								value={getExtendedInfoValue("height")}
								error={formErrors.height}
								onChange={(e) =>
									setExtendedInfoValue("height", e.target.value)
								}
							/>

							<TextField
								label="BMI"
								{...defaultHalfInputProps}
								value={getExtendedInfoValue("bmi")}
								error={formErrors.bmi}
								onChange={(e) =>
									setExtendedInfoValue("bmi", e.target.value)
								}
							/>
						</div>

						<div className="flex-row space-between modal-input-row">
							<FormControl variant="standard" {...defaultHalfInputProps}>
								<InputLabel id="demo-simple-select-standard-label">
									Blood Group
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={surrogateForm.bloodGroup}
									error={formErrors.bloodGroup}
									onChange={(e) => {
										setSurrogateForm({
											...surrogateForm,
											bloodGroup: e.target.value,
										});
										setFormErrors({
											...formErrors,
											bloodGroup: false
										});
									}}
									label="Blood Group"
									placeholder="Blood Group"
								>
									{BloodGroups.map((rel, index) => {
										return (
											<MenuItem value={rel.value} key={`bloodgroup-${index}`}>
												{rel.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>

							<FormControl variant="standard" {...defaultHalfInputProps}>
								<InputLabel id="demo-simple-select-standard-label">
									Genotype
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={surrogateForm.genoType}
									error={formErrors.genoType}
									onChange={(e) => {
										setSurrogateForm({
											...surrogateForm,
											genoType: e.target.value,
										});
										setFormErrors({
											...formErrors,
											genoType: false
										});
									}}
									label="Genotype"
									placeholder="Genotype"
								>
									{GeneTypes.map((rel, index) => {
										return (
											<MenuItem value={rel.value} key={`genoType-${index}`}>
												{rel.value}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>

						<div className="flex-row space-between modal-input-row">
							<FormControl variant="standard" {...defaultHalfInputProps}>
								<InputLabel id="demo-simple-select-standard-label">
									Tribe
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={surrogateForm.tribe}
									error={formErrors.tribe}
									onChange={(e) => {
										setSurrogateForm({
											...surrogateForm,
											tribe: e.target.value,
										});
										setFormErrors({
											...formErrors,
											tribe: false
										});
									}}
									label="Tribe"
									placeholder="Tribe"
								>
									{Tribes.map((tribe) => {
										return (
											<MenuItem value={tribe.tribe} key="hiv-status-false">
												{tribe.tribe}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							<FormControl variant="standard" {...defaultHalfInputProps}>
								<InputLabel id="demo-simple-select-standard-label">
									Religion
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={surrogateForm.religion}
									error={formErrors.religion}
									onChange={(e) => {
										setSurrogateForm({
											...surrogateForm,
											religion: e.target.value,
										});
										setFormErrors({
											...formErrors,
											religion: false
										});
									}}
									label="Religion"
									placeholder="Religion"
								>
									{Religions.map((rel) => {
										return (
											<MenuItem value={rel.tribe} key="hiv-status-false">
												{rel.tribe}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>

						<br />
						<span className="fw-600 poppins px-24">
							Next of KIN Information
						</span>
						<br />

						<div className="flex-row space-between modal-input-row">
							<TextField
								label="Next of Kin Full Name"
								value={surrogateForm.nextOfKin.name}
								error={formErrors.nextOfKin_name}
								{...defaultFullInputProps}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										nextOfKin: {
											...surrogateForm.nextOfKin,
											name: e.target.value,
										},
									})
									setFormErrors({
										...formErrors,
										nextOfKin_name: false
									});
								}}
								placeholder="Next of Kin Full Name"
							/>
						</div>
						<div className="flex-row space-between modal-input-row">
							<TextField
								label="Area"
								value={surrogateForm?.nextOfKin?.area}
								error={formErrors.nextOfKin_area}
								{...defaultFullInputProps}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										nextOfKin: {
											...surrogateForm.nextOfKin,
											area: e.target.value,
										},
									})
									setFormErrors({
										...formErrors,
										nextOfKin_area: false
									});
								}}
								placeholder="Area"
							/>
						</div>
						<div className="flex-row space-between modal-input-row">
							<TextField
								label="Address"
								value={surrogateForm.nextOfKin.address}
								error={formErrors.nextOfKin_address}
								{...defaultFullInputProps}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										nextOfKin: {
											...surrogateForm.nextOfKin,
											address: e.target.value,
										},
									})
									setFormErrors({
										...formErrors,
										nextOfKin_address: false
									});
								}}
							/>
						</div>
						<div className="flex-row space-between modal-input-row">
							<TextField
								label="State"
								value={surrogateForm.nextOfKin.state}
								error={formErrors.nextOfKin_state}
								{...defaultHalfInputProps}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										nextOfKin: {
											...surrogateForm.nextOfKin,
											state: e.target.value,
										},
									})
									setFormErrors({
										...formErrors,
										nextOfKin_state: false
									});
								}}
								placeholder="State"
							/>
							<TextField
								label="City"
								value={surrogateForm.nextOfKin.city}
								error={formErrors.nextOfKin_city}
								{...defaultHalfInputProps}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										nextOfKin: {
											...surrogateForm.nextOfKin,
											city: e.target.value,
										},
									})
									setFormErrors({
										...formErrors,
										nextOfKin_city: false
									});
								}}
								placeholder="City"
							/>
						</div>
						<div className="flex-row space-between modal-input-row">
							<FormControl variant="standard" {...defaultFullInputProps}>
								<InputLabel id="demo-simple-select-standard-label">
									Country
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={surrogateForm.nextOfKin.country}
									onChange={(e) => {
										setSurrogateForm({
											...surrogateForm,
											nextOfKin: {
												...surrogateForm.nextOfKin,
												country: e.target.value,
											},
										})
										setFormErrors({
											...formErrors,
											nextOfKin_country: false
										});
									}}
									label="Country"
									placeholder="Country"
									error={formErrors.nextOfKin_country}
								>
									{countriesList.map((country, index) => {
										return (
											<MenuItem value={country.name} key={`${country.name}-${index}-country-next`}>
												{country.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>
						<div className="flex-row space-between modal-input-row">
							<TextField
								label="Next of Kin Phone Number"
								placeholder="0802-345-6789"
								value={surrogateForm.nextOfKin.phone}
								error={formErrors.nextOfKin_phone}
								{...defaultFullInputProps}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										nextOfKin: {
											...surrogateForm.nextOfKin,
											phone: e.target.value,
										},
									})
									setFormErrors({
										...formErrors,
										nextOfKin_phone: false
									});
								}}
							// onBlur={() => {
							//   setSurrogateForm({
							//     ...surrogateForm,
							//     nextOfKin: {
							//       ...surrogateForm.nextOfKin,
							//       phone: surrogateForm.nextOfKin.phone.replace("+", ""),
							//     },
							//   });
							// }}
							/>
						</div>
						<div className="flex-row space-between modal-input-row">
							<FormControl variant="standard" {...defaultFullInputProps}>
								<InputLabel id="demo-simple-select-standard-label">
									Relationship to Kin
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={surrogateForm.nextOfKin.relationship}
									onChange={(e) => {
										setSurrogateForm({
											...surrogateForm,
											nextOfKin: {
												...surrogateForm.nextOfKin,
												relationship: e.target.value,
											},
										})
										setFormErrors({
											...formErrors,
											nextOfKin_relationship: false
										});
									}}
									label="Relationship to Kin"
									placeholder="Relationship to Kin"
									error={formErrors.nextOfKin_relationship}
								>
									{consumeContext.Relationships.map((rel, index) => {
										return (
											<MenuItem value={rel.relationship} key={index}>
												{rel.relationship}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>
						<div className="flex-row space-between modal-input-row">
							<TextField
								inputProps={{
									maxLength: 11,
								}}
								label="National Identification Number"
								value={surrogateForm.nextOfKin.nationalIdentificationNumber}
								error={formErrors.nextOfKin_nationalIdentificationNumber}
								{...defaultFullInputProps}
								onChange={(e) => {
									setSurrogateForm({
										...surrogateForm,
										nextOfKin: {
											...surrogateForm.nextOfKin,
											nationalIdentificationNumber: e.target.value,
										},
									})
									setFormErrors({
										...formErrors,
										nextOfKin_nationalIdentificationNumber: false
									});
								}}
								placeholder="00000000000"
							/>
						</div>


						<div className="flex-row space-between modal-input-row">
							<FormControl variant="standard" {...defaultHalfInputProps}>
								<InputLabel id="demo-simple-select-standard-label">
									Status
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={surrogateForm.status}
									error={formErrors.status}
									onChange={(e) => {
										setSurrogateForm({
											...surrogateForm,
											status: e.target.value,
										})
										setFormErrors({
											...formErrors,
											status: false
										});
									}}
									label="Status"
									placeholder="Status"
								>
									<MenuItem value={"active"}>
										Active
									</MenuItem>
									<MenuItem value={"inactive"}>
										Inactive
									</MenuItem>
								</Select>
							</FormControl>
							{/* <TextField
								label="Experience"
								value={surrogateForm.primary.experience}
								error={formErrors.experience}
								{...defaultHalfInputProps}
								onChange={(e) =>
									setSurrogateForm({
										...surrogateForm,
										primary: {
											...surrogateForm.primary,
											experience: e.target.value,
										},
									})
								}
							/> */}
						</div>
					</div>
					<div className="flex-column modal-form-right space-between">
						<span className="flex-column align-center width-100">
							<div className="flex-column modal-form-file-container">
								<span className="px-13 poppins fw-500">
									&nbsp; &nbsp; Upload Government ID Card
								</span>
								<div className="flex-row modal-form-file width-100">
									<div className="px-13 poppins fw-500">
										{
											surrogateForm.govtIdentificationFile ?
												surrogateForm.govtIdentificationFile.name :
												surrogateForm?.governmentID.length > 0 ? (
													getFileName(surrogateForm?.governmentID)
												) : (
													<span>No File Selected</span>
												)
										}

									</div>
									<span
										className="px-13 poppins fw-500 modal-form-file-btn flex-row pointer"
										onClick={() => {
											govtIdentificationUploadRef.current.click();
										}}
									>
										Upload File
									</span>
								</div>
								<span className="px-13 poppins fw-500 modal-form-file-about">
									&nbsp; &nbsp; Acceptable format :PDF/JPG/PNG
								</span>
							</div>
							<div className="flex-column modal-form-file-container">
								<span className="px-13 poppins fw-500">
									&nbsp; &nbsp; Upload COVID Vaccination
								</span>
								<div className="flex-row modal-form-file width-100">
									<div className="px-13 poppins fw-500">
										{
											surrogateForm.covidVaccinationFile ?
												surrogateForm.covidVaccinationFile.name :
												surrogateForm?.covidVaccinationCertificate.length > 0 ? (
													getFileName(surrogateForm?.covidVaccinationCertificate)
												) : (
													<span>No File Selected</span>
												)
										}
									</div>
									<span
										className="px-13 poppins fw-500 modal-form-file-btn flex-row pointer"
										onClick={() => {
											covidVaccinationUploadRef.current.click();
										}}
									>
										Upload File
									</span>
								</div>
								<span className="px-13 poppins fw-500 modal-form-file-about">
									&nbsp; &nbsp; Acceptable format :PDF/JPG/PNG
								</span>
							</div>
						</span>
						<br />
						<div className="width-100 flex-column">
							<button
								disabled={formSubmitting || imageUploading}
								style={{
									opacity: formSubmitting || imageUploading ? "0.5" : "1",
								}}
								className="purple-btn-default px-16 poppins pointer width-100 uppercase modal-form-submit surrogate-form-btn"
								onClick={() => {
									// setCurrentFormSection(2);
									// setFormSubmitting(true);
									// UpdateFormErrors();
									UpdateSurrogate()
								}}
							>
								Update &nbsp;
								{formSubmitting || imageUploading ? (
									<i className="far fa-spinner-third fa-spin" />
								) : (
									<i className="far fa-long-arrow-alt-right" />
								)}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
